import { ACTIVATE_MODAL, DEACTIVATE_MODAL, closeModal } from 'redux-modal-dispatcher/src/actions';
import { SERVICE_ACCOUNTS_MODAL_ID } from '../constants';

const body = document.querySelector('body');

export default ({ dispatch }) => {
  document.addEventListener('keyup', e => e.key === 'Escape' && dispatch(closeModal(SERVICE_ACCOUNTS_MODAL_ID)));

  return next => (action) => {
    const result = next(action);

    if (action.type === ACTIVATE_MODAL && action.modalId === SERVICE_ACCOUNTS_MODAL_ID) {
      body.classList.add('is-holding');
    }

    if (action.type === DEACTIVATE_MODAL && action.modalId === SERVICE_ACCOUNTS_MODAL_ID) {
      body.classList.remove('is-holding');
    }

    return result;
  };
};
