import { actionTypes } from '../actions/index';

// Service Type:
//   - id: string,
//   - code: string,

const initialState = {
  byCode: {},
  allCodes: [],
};

const addService = (state, { service }) => {
  const byCode = {
    ...state.byCode,
    [service.code]: service,
  };
  return {
    byCode,
    allCodes: Object.keys(byCode),
  };
};

const addServices = (state, { services }) => (
  services.reduce((result, service) => addService(result, { service }), state)
);

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SERVICES_ADD: return addServices(state, action);
    case actionTypes.CLEAR_STATE: return { ...initialState };
    default: return state;
  }
};
