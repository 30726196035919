/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ModalDispatcher } from 'redux-modal-dispatcher';
import { TranslationsProvider } from '@airslate/platform-locales';

import { openServiceAccountsModal, closeServiceAccountsModal } from './actions/modal';

import store from './store';

import { selectCurrentServiceAccounts, selectGroupedServiceAccounts } from './selectors';
import { SERVICE_ACCOUNTS_MODAL_ID, serviceDriverCodes } from './constants';

import { appendElContainer, renderOnceTo } from './utils/index';
import { createServiceAccount as createAccountAction, setCurrentServiceAccountId, clearState } from './actions';
import { ensureServiceIsReady, createServiceAccount } from './middlewares/serviceAccountsManager';

const LazyContainerServiceAccountsModal = lazy(() => import('./containers/ContainerServiceAccountsModal'));
const LazyContainerServiceAccountsConnect = lazy(() => import('./containers/ContainerServiceAccountsConnect'));
const ContainerServiceAccountsConnectWithServices = lazy(() => import('./containers/ContainerServiceAccountsConnectWithServices'));
const LazyContainerCurrentServiceAccountConnect = lazy(() => import('./containers/ContainerCurrentServiceAccountConnect'));

const withTranslationsProvider = Inner => {
  const Wrapped = props => (
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <TranslationsProvider namespaces="service-account-modal">
          <Inner {...props} />
        </TranslationsProvider>
      </Suspense>
    </Provider>
  );

  Wrapped.displayName = `withTranslationsProvider(${Inner.displayName || Inner.name})`;
  return Wrapped;
};

const ServiceAccountsApp = () => (
  <ModalDispatcher>
    <LazyContainerServiceAccountsModal id={SERVICE_ACCOUNTS_MODAL_ID} />
  </ModalDispatcher>
);
const TranslatedServiceAccountsApp = withTranslationsProvider(ServiceAccountsApp);

export const openExternalServiceAccountsModal = (
  serviceCode,
  user,
  {
    shouldClearState = true,
    parentSelector = 'body',
    selectedServiceAccountId = null,
    ServiceOptionsFormComponent = null,
    additionalOptionsHeaderText = '',
    handleError = null,
  } = {},
) => {
  renderOnceTo(SERVICE_ACCOUNTS_MODAL_ID, () => {
    const app = appendElContainer(SERVICE_ACCOUNTS_MODAL_ID, parentSelector);
    ReactDOM.render(<TranslatedServiceAccountsApp />, app);
  });
  const action = openServiceAccountsModal({
    serviceCode,
    user,
    ServiceOptionsFormComponent,
    shouldClearState,
    // TODO: use locales from API, when global solution will be ready
    additionalOptionsHeaderText,
    handleError,
  });

  store.dispatch(setCurrentServiceAccountId(serviceCode, selectedServiceAccountId));

  return ensureServiceIsReady(store, { serviceCode })
    .then(() => store.dispatch(action))
    .catch(error => {
      if (typeof handleError === 'function') {
        handleError(error);
      } else {
        throw error;
      }
    });
};

export const closeExternalServiceAccountsModal = () => store.dispatch(closeServiceAccountsModal());
export const getCurrentServiceAccounts = () => selectCurrentServiceAccounts(store.getState());
export const getGroupedServiceAccounts = () => selectGroupedServiceAccounts(store.getState());
export const clearStateServiceAccounts = () => store.dispatch(clearState());

/* eslint-disable react/prop-types */

const ServiceAccountsConnect = ({ children }) => (
  <LazyContainerServiceAccountsConnect>
    {children}
  </LazyContainerServiceAccountsConnect>
);

const TranslatedServiceAccountsConnect = withTranslationsProvider(ServiceAccountsConnect);

const TranslatedServiceAccountsConnectWithServices = (
  withTranslationsProvider(ContainerServiceAccountsConnectWithServices)
);

const CurrentServiceAccountConnect = ({ serviceCode, children }) => (
  <LazyContainerCurrentServiceAccountConnect serviceCode={serviceCode}>
    {children}
  </LazyContainerCurrentServiceAccountConnect>
);

const TranslatedCurrentServiceAccountConnect = withTranslationsProvider(
  CurrentServiceAccountConnect,
);

const createAccount = (serviceCode, options, credential) => {
  const action = createAccountAction(serviceCode, options, credential);

  return ensureServiceIsReady(store, action)
    .then(() => createServiceAccount(store, action));
};

export {
  createAccount as createServiceAccount,
  TranslatedServiceAccountsConnect as ServiceAccountsConnect,
  TranslatedCurrentServiceAccountConnect as CurrentServiceAccountConnect,
  TranslatedServiceAccountsConnectWithServices as ServiceAccountsConnectWithServices,
  serviceDriverCodes,
};
