/* eslint-disable no-await-in-loop */
import { MAX_LENGTH_OF_ENCODED_GET_REQUEST } from '../constants';

export const appendElContainer = (elementId, parentSelector) => {
  const el = document.createElement('div');
  el.setAttribute('id', elementId);
  return document.querySelector(parentSelector).appendChild(el);
};

export const renderOnceTo = (elementId, renderFn) => {
  if (!document.getElementById(elementId)) {
    renderFn();
  }
};

export const flatMerge = entities => entities.reduce((acc, entity) => ({ ...acc, ...entity }));

export const allSettled = promises => Promise.all(promises.map(p => p.catch(e => e)))
  .then(results => results);

export const loadAll = (loader, mergeFunc = flatMerge) => async (...args) => {
  let loadedEntities = {};

  const { pagination, entities: firstPageEntities } = await loader(1, ...args);

  const restPages = new Array(pagination.lastPage - pagination.currentPage)
    .fill(null)
    .map((_, index) => index + 2);

  if (restPages.length) {
    const actions = restPages.map(page => loader(page, ...args));
    const restEntities = await allSettled(actions);

    loadedEntities = mergeFunc([
      firstPageEntities, ...restEntities.map(({ entities }) => entities),
    ]);
  } else {
    loadedEntities = { ...firstPageEntities };
  }

  return loadedEntities;
};

export const readPagination = meta => {
  const {
    currentPage,
    from,
    lastPage,
    perPage,
    to,
    total,
  } = meta;

  return {
    currentPage,
    from,
    lastPage,
    perPage,
    to,
    total,
    hasMore: currentPage < lastPage,
  };
};

export const queryWithParams = (baseUrl, queryParams, filterParams = {}) => (
  Object.entries(queryParams)
    .reduce((acc, [key, value]) => (
      value
        ? [...acc, ([key, encodeURIComponent(value)])]
        : acc
    ), [])
    .concat(Object.entries(filterParams)
      .reduce((acc, [key, value]) => (
        value
          ? [...acc, ([`filter[${key}]`, encodeURIComponent(value)])]
          : acc
      ), []))
    .filter(param => param[1])
    .reduce((res, [key, value]) => `${res}${key}=${value}&`, `${baseUrl}?`)
    .slice(0, -1)
    .slice(0, MAX_LENGTH_OF_ENCODED_GET_REQUEST)
);
