import {
  fetchServices,
  authorizeServiceAccount,
  requestServiceAccount,
  fetchServiceAccountById,
  fetchServiceAccounts,
} from '../api';

import {
  actionTypes,
  addServices,
  addServiceAccount,
  updateServiceAccountsByCode,
  addServiceAccountCode,
} from '../actions';
import { selectServices } from '../selectors';
import { loadAll } from '../utils';

const ensureServicesAreReady = ({ dispatch, getState }, { serviceCodes }) => {
  const services = selectServices(getState());
  const codes = serviceCodes.filter(code => !services[code]);
  return codes.length === 0 ? Promise.resolve() : (
    fetchServices(codes).then(result => dispatch(addServices(result)))
  );
};

export const ensureServiceIsReady = (store, { serviceCode }) => (
  ensureServicesAreReady(store, { serviceCodes: [serviceCode] })
);

const preloadServiceAccounts = ({ dispatch }, { serviceCodes }) => {
  const promises = serviceCodes.map((code) => loadAll(fetchServiceAccounts)(code)
    .then(
      accountsMap => Object.values(accountsMap)
        .filter(account => !code || code === account.serviceCode),
    )
    .then(accounts => (
      dispatch(accounts.length > 0
        ? updateServiceAccountsByCode(accounts, code) : addServiceAccountCode(code))
    )));
  return Promise.all(promises);
};

export const createServiceAccount = async (
  { dispatch, getState },
  { serviceCode, options, credential },
) => {
  const { id: serviceId } = selectServices(getState())[serviceCode];
  let serviceAccount = await requestServiceAccount(serviceId, options, credential);
  const authUrl = serviceAccount.attributes.authorization_url;

  if (authUrl) {
    const serviceAccountId = await authorizeServiceAccount(authUrl);
    serviceAccount = await fetchServiceAccountById(serviceAccountId);
  }

  dispatch(addServiceAccount(serviceAccount));

  return serviceAccount;
};

export default store => next => (action) => {
  const result = next(action);

  switch (action.type) {
    case actionTypes.SERVICE_ACCOUNTS_PRELOAD:
      return ensureServicesAreReady(store, action)
        .then(() => preloadServiceAccounts(store, action));
    case actionTypes.SERVICE_ACCOUNT_CREATE:
      return ensureServiceIsReady(store, action)
        .then(() => createServiceAccount(store, action));
    default: return result;
  }
};
