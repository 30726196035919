import api from 'airslate-api-client';
import { readAsLookup } from 'json-api-reader';
import { queryWithParams, readPagination } from '../utils';
import { serviceCodes } from '../constants';
import { $t } from '../services/localization';

export const fetchServices = (codes = []) => api()
  .get(queryWithParams('services', {}, { code: codes.join(',') }))
  .then(readAsLookup)
  .then(({ entities: { services = {} } = {} } = {}) => Object.values(services));

export const requestServiceAccount = (serviceId, options, credential) => (
  api()
    .post('service-accounts', {
      data: {
        type: 'service_accounts',
        ...(options
          ? { attributes: { options } }
          : {}),
        relationships: {
          service: {
            data: {
              type: 'services',
              id: serviceId,
            },
          },
        },
        ...(credential && { meta: { credential } }),
      },
    })
    .then(({ data }) => data)
);

export const authorizeServiceAccount = async authUrl => new Promise((resolve, reject) => {
  const tab = window.open(authUrl);

  const handleIntegration = (res) => {
    window.removeEventListener('message', handleIntegration);
    const { data, origin } = res;
    if (typeof data === 'string' && origin.match(/\.airslate[-]*[a-z0-9]*.(xyz|com)/i)) {
      let parsedIntegration;
      try {
        parsedIntegration = JSON.parse(data);
        tab.close();
      } catch (ex) {
        // eslint-disable-next-line no-undef
        if (__LOCAL__ || __DEV__) {
          // eslint-disable-next-line no-console
          console.warn(ex);
        }
      }

      if (parsedIntegration && parsedIntegration.status) {
        resolve(parsedIntegration.integration_id);
      } else {
        reject(new Error($t('FALSY_INTEGRATION_STATUS_ERROR')));
      }
    }
  };

  window.addEventListener('message', handleIntegration);
});

const normalizeServiceAccount = ({ id, attributes, relationships }) => ({
  id,
  name: attributes.name,
  finished: attributes.finished,
  serviceCode: (
    // This is a hacky way to resolve a problem of users, whose GOOGLE_DRIVE accounts became
    // GOOGLE one. It should be removed after the problem will be solved on the backend side.
    attributes.service_code === serviceCodes.GOOGLE
      ? serviceCodes.GOOGLE_DRIVE
      : attributes.service_code
  ),
  accountName: attributes.account_name,
  accountIdentifier: attributes.account_identifier,
  createdAt: attributes.created_at,
  expiresOn: attributes.expires_on,
  updatedAt: attributes.updated_at,
  owner: relationships.owner.data.id,
});

export const fetchServiceAccounts = (page, code) => api()
  .get(queryWithParams('service-accounts', { page }, { 'service.code': code }))
  .then(readAsLookup)
  .then(({ entities: { serviceAccounts }, meta }) => ({
    entities: serviceAccounts,
    pagination: readPagination(meta),
  }));

export const fetchServiceAccountById = serviceAccountId => (
  api()
    .get(`service-accounts/${serviceAccountId}`)
    .then(({ data }) => normalizeServiceAccount(data))
);
