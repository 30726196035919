export const serviceCodes = {
  GOOGLE_DRIVE: 'GOOGLE_DRIVE',
  GOOGLE: 'GOOGLE',
};

export const serviceDriverCodes = {
  BOX: 'BOX',
  ONEDRIVE: 'ONEDRIVE',
  DROPBOX: 'DROPBOX',
  GOOGLE_DRIVE: serviceCodes.GOOGLE_DRIVE,
  SHAREPOINT: 'SHAREPOINT',
};

export const SERVICE_ACCOUNTS_MODAL_ID = 'service-accounts-dialog';

export const MAX_LENGTH_OF_ENCODED_GET_REQUEST = 2048;

export const MODAL_MODES = {
  SERVICES_ACCOUNTS: 'SERVICES_ACCOUNTS',
  SERVICE_OPTIONS: 'SERVICE_OPTIONS',
};
