export const ACTION_TYPE_PREFIX = 'EX_SI::';

export const actionTypes = {
  SERVICES_INIT: `${ACTION_TYPE_PREFIX}SERVICES_INIT`,
  SERVICES_ADD: `${ACTION_TYPE_PREFIX}SERVICES_ADD`,
  SERVICE_ACCOUNT_CREATE: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNT_CREATE`,
  SERVICE_ACCOUNTS_PRELOAD: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNTS_PRELOAD`,
  SERVICE_ACCOUNT_ADD: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNT_ADD`,
  SERVICE_ACCOUNTS_ADD: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNTS_ADD`,
  SERVICE_ACCOUNTS_UPDATE: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNTS_UPDATE`,
  SERVICE_ACCOUNTS_ADD_CODE: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNTS_ADD_CODE`,
  SERVICE_ACCOUNT_SET_CURRENT: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNT_SET_CURRENT`,
  CHANGE_MODAL_MODE: `${ACTION_TYPE_PREFIX}CHANGE_MODAL_MODE`,
  CLEAR_STATE: `${ACTION_TYPE_PREFIX}SERVICE_ACCOUNTS_CLEAR_STATE`,
};

export const addServices = services => ({
  type: actionTypes.SERVICES_ADD,
  services,
});

export const createServiceAccount = (serviceCode, options, credential) => ({
  type: actionTypes.SERVICE_ACCOUNT_CREATE,
  serviceCode,
  options,
  credential,
});

export const preloadServiceAccounts = serviceCodes => ({
  type: actionTypes.SERVICE_ACCOUNTS_PRELOAD,
  serviceCodes,
});

export const addServiceAccount = serviceAccount => ({
  type: actionTypes.SERVICE_ACCOUNT_ADD,
  serviceAccount,
});

export const addServiceAccounts = serviceAccounts => ({
  type: actionTypes.SERVICE_ACCOUNTS_ADD,
  serviceAccounts,
});

export const updateServiceAccountsByCode = (serviceAccounts, serviceCode) => ({
  type: actionTypes.SERVICE_ACCOUNTS_UPDATE,
  serviceAccounts,
  serviceCode,
});

export const clearState = () => ({
  type: actionTypes.CLEAR_STATE,
});

export const addServiceAccountCode = serviceCode => ({
  type: actionTypes.SERVICE_ACCOUNTS_ADD_CODE,
  serviceCode,
});

export const setCurrentServiceAccountId = (serviceCode, serviceAccountId) => ({
  type: actionTypes.SERVICE_ACCOUNT_SET_CURRENT,
  serviceCode,
  serviceAccountId,
});

export const changeModalMode = mode => ({
  type: actionTypes.CHANGE_MODAL_MODE,
  mode,
});
