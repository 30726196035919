import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducers as modalReducers } from 'redux-modal-dispatcher';

import servicesData from '../reducers';
import { domController, serviceAccountsManager } from '../middlewares';

const middlewares = [
  thunk,
  domController,
  serviceAccountsManager,
];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(require('redux-logger').createLogger({ collapsed: true })); // eslint-disable-line @typescript-eslint/no-var-requires
}

const composeEnhancers = (
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    name: 'service-account-modal',
  })
) || compose;

const store = createStore(
  combineReducers({ servicesData, ...modalReducers }),
  composeEnhancers(applyMiddleware(...middlewares)),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
