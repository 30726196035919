import { actionTypes } from '../actions/index';
import { MODAL_MODES } from '../constants';

const initialState = {
  mode: MODAL_MODES.SERVICES_ACCOUNTS,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CHANGE_MODAL_MODE: {
      return { ...state, mode: action.mode };
    }
    case actionTypes.CLEAR_STATE: return { ...initialState };
    default: return state;
  }
};
