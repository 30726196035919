import { createSelector } from 'reselect';

const rootSelector = state => state.servicesData;

const getServiceCode = (_, { serviceCode }) => serviceCode;

const selectModesState = createSelector(rootSelector, state => state.modalModes);

export const selectServices = createSelector(
  rootSelector,
  ({ services }) => services.byCode,
);

export const selectServiceAccounts = createSelector(
  rootSelector,
  ({ serviceAccounts }) => serviceAccounts.byId,
);

export const selectGroupedServiceAccounts = createSelector(
  rootSelector,
  ({ serviceAccounts }) => (
    Object
      .entries(serviceAccounts.groupedByServiceCode)
      .reduce((result, [serviceCode, serviceAccountIds]) => ({
        ...result,
        [serviceCode]: serviceAccountIds.map(id => serviceAccounts.byId[id]),
      }), {})
  ),
);

export const selectWasLoadedServiceAccounts = createSelector(
  rootSelector,
  getServiceCode,
  ({ serviceAccounts }, serviceCode) => Array.isArray(
    serviceAccounts.groupedByServiceCode[serviceCode],
  ),
);

export const selectCurrentServiceAccounts = createSelector(
  rootSelector,
  ({ services, serviceAccounts }) => {
    const { byId, currentIds } = serviceAccounts;
    return services.allCodes.reduce((result, serviceCode) => ({
      ...result,
      [serviceCode]: byId[currentIds[serviceCode]],
    }), {});
  },
);

export const selectModalMode = createSelector(
  selectModesState,
  ({ mode }) => mode,
);
