import { actionTypes } from '../actions/index';

// ServiceAccount Type:
//   - id: string
//   - name: string
//   - finished: boolean
//   - serviceCode: string
//   - accountName: string
//   - displayName: string
//   - accountIdentifier: string
//   - createdAt: string
//   - expiresOn: string
//   - updatedAt: string
//   - owner: string

const initialState = {
  byId: {},
  groupedByServiceCode: {},
  currentIds: {},
};

const addServiceAccount = (state, { serviceAccount }) => {
  const { byId, groupedByServiceCode } = state;
  const { id, serviceCode } = serviceAccount;

  if (byId[id]) {
    return state;
  }

  return {
    ...state,
    byId: {
      ...byId,
      [id]: serviceAccount,
    },
    groupedByServiceCode: {
      ...groupedByServiceCode,
      [serviceCode]: (
        groupedByServiceCode[serviceCode] ? [...groupedByServiceCode[serviceCode], id] : [id]
      ),
    },
  };
};

const addServiceAccounts = (state, { serviceAccounts }) => (
  serviceAccounts.reduce((result, serviceAccount) => (
    addServiceAccount(result, { serviceAccount })
  ), state)
);

const updateServiceAccountsById = (state, { serviceAccounts, serviceCode }) => ({
  ...state,
  byId: serviceAccounts.reduce((result, serviceAccount) => ({
    ...result,
    [serviceAccount.id]: serviceAccount,
  }), state.byId),
  groupedByServiceCode: {
    ...state.groupedByServiceCode,
    [serviceCode]: serviceAccounts.map(serviceAccount => serviceAccount.id),
  },
});

const addServiceAccountsCode = (state, { serviceCode }) => ({
  ...state,
  groupedByServiceCode: {
    ...state.groupedByServiceCode,
    [serviceCode]: state.groupedByServiceCode[serviceCode] || [],
  },
});

const setCurrentServiceAccountId = (state, { serviceCode, serviceAccountId }) => ({
  ...state,
  currentIds: {
    ...state.currentIds,
    [serviceCode]: serviceAccountId,
  },
});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SERVICE_ACCOUNT_ADD: return addServiceAccount(state, action);
    case actionTypes.SERVICE_ACCOUNTS_ADD: return addServiceAccounts(state, action);
    case actionTypes.SERVICE_ACCOUNTS_UPDATE: return updateServiceAccountsById(state, action);
    case actionTypes.SERVICE_ACCOUNTS_ADD_CODE: return addServiceAccountsCode(state, action);
    case actionTypes.SERVICE_ACCOUNT_SET_CURRENT: return setCurrentServiceAccountId(state, action);
    case actionTypes.CLEAR_STATE: return { ...initialState };
    default: return state;
  }
};
